import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png"
export default function Listings(props) {
  const [releases, setReleases] = useState([]);
  useEffect(() => {
    fetch(
      "https://m2n3yrv1q8.execute-api.eu-west-2.amazonaws.com/mantis/list-release-bucket",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: props.session.accessToken.jwtToken,
        },
        mode: "cors",
      }
    )
      .then((data) => data.json())
      .then((data) => {
        setReleases(data);
        console.log(data);
      });
  }, [props.session.accessToken.jwtToken]);
  return (
    <div className=" avenir white">
      <header className="w-100 ph3 pv3 pv4-ns ph4-m ph5-l">
        <nav className="f6 fw6 ttu tracked flex items-center">
          <img className="w3" alt="mantis" src={logo}/>
          <p className="lh-title link white dib mr3 ml3 f3" href="#" title="Home">
            Mantis Releases
          </p>
        </nav>
      </header>

      <div className="pa4 listing ">
        <div className="overflow-auto">
          <table className="f5 w-100 mw8 center" cellSpacing="0">
            <tbody className="lh-copy">
              {releases.sort((x,y)=> new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1)
              .map((release) => (
                <tr key={release.time} className="stripe-dark link">
                  <td className="pa3 white">{release.time}</td>
                  <td className="pa3">
                    <a className="blue" href={release.presignedUrl}>
                      {release.key}
                    </a>
                  </td>
                  <td className="pa3">
                    {Math.round(release.size / 1000000)} MiB
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
