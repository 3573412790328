import { useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsConfig from "./aws-exports";
import Listings from "./pages/Listings";

const isLocalhost = Boolean(window.location.hostname === "localhost");

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

function App() {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event);
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => {
            setUser(userData);
            setIsLoadingUser(false);
          });
          getSession().then((sessionData) => setSession(sessionData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
      }
    });

    getUser().then((userData) => {
      setUser(userData);
      setIsLoadingUser(false);
    });
    getSession().then((sessionData) => setSession(sessionData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  function getSession() {
    return Auth.currentSession()
      .then((sessionData) => sessionData)
      .catch(() => console.log("Not signed in"));
  }

  if (isLoadingUser) {
    return <p>loading</p>;
  }
  if (user) {
    return <Listings user={user} session={session} />;
  } else {
    return (
      <div className="flex justify-center align-center items-center vh-100">

        <a class="f3 link dim ba bw1 ph3 pv2 mb2 dib light-gray" href="#0" onClick={() => Auth.federatedSignIn({ provider: "Okta" })}>Login via Okta</a>
      </div>
    );
  }
}

export default App;
