
const awsConfig = {
  region: "eu-west-2",
  userPoolId: "eu-west-2_LdNj8U324",
  userPoolWebClientId: "4srmmk3qa5n8fc5v56edp39h22",
  
  oauth: {
    provider: "Okta",
    domain: "vaarst.auth.eu-west-2.amazoncognito.com",
    scope: ["email", "openid", "profile"],
    redirectSignIn: "http://localhost:3000,https://mantis.vaarst.dev",
    redirectSignOut: "http://localhost:3000,https://mantis.vaarst.dev/",
    responseType: "code"
  }
  
}

export default awsConfig 